@import '../../../scss/helpers/variables';

.quote-navigation {
  position: fixed;
  top: $headerHeight;
  background: #ffffff;
  border-bottom: 1px solid $background;
  width: 100%;
  left: 0;
  // text-align: center;
  z-index: $header-z;
  font-size: 14px;

  ul {
    white-space: nowrap;
    overflow-x: auto;
    margin: 0 30px;
    padding: 0;
  }
  li {
    display: inline-block;
  }
  li + li {
    margin-left: 15px;
  }
  a {
    display: block;
    padding: 15px 0;
  }
  a.active {
    border-bottom: 5px solid $eon-bordeaux;
  }
}

@media screen and (max-width: 800px) {
  .quote-navigation {
    display: none;
  }
}
