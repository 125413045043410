@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

/*==============================
  Shared Styles
==============================*/
.quote__your-details__customer-details {
  margin-bottom: 24px;
  .key {
    width: 160px;
  }
}
.quote__your-details__customer-details__row {
  width: 100%;
  display: table;
  table-layout: fixed;
  div {
    display: table-cell;
  }
}
.quote__your-details__customer-details__row + .quote__your-details__customer-details__row {
  margin-top: 10px;
}
.quote__your-details__customer-details__key {
  width: 160px;
}

.quote__your-details__checklist {
  list-style-type: none !important;
  padding: 0 !important;

  li {
    position: relative;
    padding: 0 0 4px 32px;
    margin-bottom: 8px;
    text-align: left;
    z-index: -1;
  }
  li:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 22px;
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M1.62 1.63C2.69.42 5.65-.11 10.5 0c4.85-.13 7.81.4 8.88 1.61S21 5.8 21 10.5s-.54 7.66-1.62 8.87-4 1.74-8.88 1.61c-4.85.13-7.81-.4-8.88-1.61S0 15.2 0 10.5s.54-7.66 1.62-8.87z" fill="#{$eon-red}"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10.5l3.5 4 8-7.5"/></svg>'
    );
    background-size: 100%;
  }
}

/*==============================
  Web Specific Styles
==============================*/
.quote-page--web {
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
