@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__your-solution {
}
.quote__your-solution__product {
  display: table;
  table-layout: fixed;
  max-width: 480px;
  margin-bottom: 24px;
}
.quote__your-solution__product__col {
  display: table-cell;
  vertical-align: top;
}
.quote__your-solution__product__col:nth-child(1) {
  width: 180px;
  .product-image {
    border-right: 2px solid $eon-lime;
  }
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 800px) {
  .quote-page--web {
    .quote__your-solution {
      display: block;
      width: 100%;
    }
  }
}
@media screen and (max-width: 680px) {
  .quote-page--web {
    .quote__your-solution__product {
      width: 100%;
    }
    .quote__your-solution__product__col:nth-child(1) {
      width: 145px;
    }
    .product-image {
      width: 120px;
      height: 120px;
    }
  }
}
/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
  .quote__your-solution {
  }
}
