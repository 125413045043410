@import '../../scss/helpers/mixins';

.icon-btn {
  @include button-reset;
  width: 40px;
  height: 40px;
  position: relative;

  .border {
    display: block;
    width: 40px;
    height: 40px;
  }

  .icon {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 28px;
    height: 28px;
  }
}
