@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__savings-and-benefits {
  .smallprint {
    font-size: 12px;
    line-height: 16px;
    li {
      margin-bottom: 1em;
    }
  }
  sup {
    position: relative;
    font-size: 10px;
    top: -4px;
    left: -2px;
  }
}
.quote__savings-and-benefits__savings-grid {
  display: table;
  table-layout: fixed;
  margin: 40px 0;
}
.quote__savings-and-benefits__savings-grid__col {
  display: table-cell;
  vertical-align: middle;
}
.quote__savings-and-benefits__savings-grid__col + .quote__savings-and-benefits__savings-grid__col {
  padding-left: 40px;
  width: 335px;
}
.quote__savings-and-benefits__savings-table {
  .totals:before {
    background: $eon-red !important;
  }
  .equals {
    width: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
    vertical-align: middle;
    &:before,
    &:after {
      display: none !important;
    }
  }
}
.quote__savings-and-benefits__red-box {
  background: $eon-red;
  color: #ffffff;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  height: 100%;
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 800px) {
  .quote-page--web {
    .quote__savings-and-benefits__savings-grid__col {
      display: block;
      width: 100%;
    }
    .quote__savings-and-benefits__savings-grid__col + .quote__savings-and-benefits__savings-grid__col {
      padding: 30px 0 0 0;
    }
  }
}
/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf .quote__savings-and-benefits {
}
