@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

.quote__intro--pdf {
  .quote-section__content--left {
    padding-top: 0;
  }
  .quote-section__content--right {
    background: $eon-bordeaux;
    padding: 0;
    position: relative;
  }
  // We are nesting sections, so remove paddings and page breaks from nested ones
  .quote-section__content--left .quote-section__content--left {
    padding: 0;
  }
  .quote__your-details {
    margin-top: 24px;
  }
}
.quote__intro--pdf__intro {
  text-align: right;
  margin-bottom: 40px;

  h1 {
    font-size: 45px;
    line-height: 1;
    clear: both;
  }
  .eon-logo {
    width: 303px;
    height: 149px;
    float: right;
    margin-right: -35px;
  }
}
.quote__intro--pdf__header-img {
  height: 325px;
  // background-image set inline (uses env var)
  background-repeat: no-repeat;
  background-position: -100px;
}
.quote__intro--pdf .solar_contact {
  background: $eon-bordeaux;
  border: none;
}
.quote__intro--pdf__fca-disclaimer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  color: white;
  font-size: 0.8em;
}
