@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

/*==============================
  Shared Styles
==============================*/
.quote__what-happens-next__steps {
  display: table;
  height: 1px; // hack, need defined height so children can be 100% of it, but it will grow as needed!
  margin: 0 -10px;
}

.quote__what-happens-next__steps__col {
  display: table-cell;
  width: 25%;
  height: 100%;
  padding: 0 10px;
}

[class^='quote__what-happens-next__steps__step'] {
  height: 100%;
  background: $background-light;
  padding: 20px;
  border-left: 5px solid;
  h3 {
    color: #ffffff;
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-size: 100%;
  }
}
.quote__what-happens-next__steps__step--1 {
  border-color: $eon-lime;
  h3 {
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-lime}"/></svg>'
    );
  }
}
.quote__what-happens-next__steps__step--2 {
  border-color: $eon-red;
  h3 {
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-red}"/></svg>'
    );
  }
}
.quote__what-happens-next__steps__step--3 {
  border-color: $eon-turquoise;
  h3 {
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-turquoise}"/></svg>'
    );
  }
}
.quote__what-happens-next__steps__step--4 {
  border-color: $eon-bordeaux;
  h3 {
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-bordeaux}"/></svg>'
    );
  }
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 800px) {
  .quote-page--web {
    .quote__what-happens-next__steps__col {
      display: block;
      width: 100%;
      height: auto;
    }
    .quote__what-happens-next__steps__col + .quote__what-happens-next__steps__col {
      margin-top: 20px;
    }
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
