@import '../../scss/helpers/variables';

.toast {
  position: fixed;
  width: 450px;
  background: #ffffff;
  padding: 30px;
  z-index: $modal-z;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

//  Header
.toast__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid $background;
  margin-bottom: 1.25rem;
}
.toast__close,
.toast_icon {
  flex: none;
  width: auto;
}
.toast__title {
  flex: 1;
  margin-bottom: 0;
  padding-right: 0.5rem;
}
.toast__icon + .toast__title {
  padding-left: 0.5rem;
}

// Body
.toast__body {
  display: flex;
  align-items: center;
}
.toast__body__content {
  flex: 1;
}
.toast__body__button {
  flex: none;
  width: auto;
}

// Positioning
.toast--top-left {
  top: 0;
  left: 0;
  border-bottom-right-radius: 10px;
}
.toast--top-right {
  top: 0;
  right: 0;
  border-bottom-left-radius: 10px;
}
.toast--bottom-left {
  bottom: 0;
  left: 0;
  border-top-right-radius: 10px;
}
.toast--bottom-right {
  bottom: 0;
  right: 0;
  border-top-left-radius: 10px;
}
