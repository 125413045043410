@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__what-now {
  .quote-section__content--right {
    background: $eon-lime;
  }
}

/*==============================
  Web Specific Styles
==============================*/
.quote-page--web {
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
