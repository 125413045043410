@import '../../scss/helpers/variables';

.product-image {
  width: 150px;
  height: 150px;
}

.product-image-image,
.product-image-placeholder {
  width: 100%;
  height: 100%;
}

.product-image-image {
  object-fit: contain;
}

.product-image-placeholder {
  display: flex;
  align-items: center;
  text-align: center;
  background: #cccccc;
  color: $eon-red;
  font-weight: bold;
}
