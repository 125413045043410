.quote__roof-images__roof {
  page-break-inside: avoid;
}

.quote__roof-images__roof__image {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
.quote__roof-images__roof + .quote__roof-images__roof {
  margin-top: 30px;
}
