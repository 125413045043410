/*==============================
  Colours
==============================*/
$eon-red: #ea1c0a;
$eon-red-hover: #d51607;

$eon-lime: #e3e000;
$eon-lime-hover: #dad600;
$eon-lime-75: transparentize($eon-lime, 0.75);
$eon-lime-75: transparentize($eon-lime, 0.5);
$eon-lime-75: transparentize($eon-lime, 0.25);

$eon-turquoise: #1ea2b1;
$eon-turquoise-hover: #2b91a3;
$eon-turquoise-75: transparentize($eon-turquoise, 0.75);
$eon-turquoise-75: transparentize($eon-turquoise, 0.5);
$eon-turquoise-75: transparentize($eon-turquoise, 0.25);

$eon-bordeaux: #b00402;
$eon-bordeaux-hover: #a00301;
$eon-bordeaux-hover: #a00301;
$eon-bordeaux-75: transparentize($eon-bordeaux, 0.75);
$eon-bordeaux-75: transparentize($eon-bordeaux, 0.5);
$eon-bordeaux-75: transparentize($eon-bordeaux, 0.25);

$background: #e8e8e8;
$background-light: #f6f6f7;

$copy: #39393a;
$copy-light: #8f9192;

/*==============================
  Z-Indexes
==============================*/
$header-z: 5;
$menu-z: 10;
$modal-z: 15;

/*==============================
  Misc
==============================*/
$headerHeight: 70px;
$tabHeight: 50px;
