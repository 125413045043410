/*========================================
	Grid Base Styles
========================================*/
.grid {
  display: flex;
  flex-wrap: wrap;
  &:last-child {
    margin-bottom: 0;
  }
}

/*========================================
	Grid Align (Horizontal)
========================================*/
.grid-h-left {
  justify-content: flex-start;
}
.grid-h-center {
  justify-content: center;
}
.grid-h-right {
  justify-content: flex-end;
}
.grid-h-fill {
  justify-content: space-between;
}

/*========================================
	Grid Align (Vertical)
========================================*/
.grid-v-top {
  align-items: flex-start;
}
.grid-v-center {
  align-items: center;
}
.grid-v-bottom {
  align-items: flex-end;
}

/*========================================
	Gutters
========================================*/
$gutters: (
  lg: 60px,
  md: 40px,
  sm: 20px,
  xs: 10px,
);
@each $gutName, $gutVal in $gutters {
  .grid-gutters-#{$gutName} {
    margin-left: -($gutVal/2);
    margin-right: -($gutVal/2);
    margin-top: -($gutVal);
    margin-bottom: $gutVal;
    > .col {
      padding-left: $gutVal/2;
      padding-right: $gutVal/2;
      padding-top: $gutVal;
    }
  }
}

/*==============================
  Column Base Styles
==============================*/
.col {
  flex: 1;
  min-width: 1px;
}

/*==============================
  Vertical Alignment
==============================*/
.col-v-top {
  align-self: flex-start;
}
.col-v-center {
  align-self: center;
}
.col-v-bottom {
  align-self: flex-end;
}

/*==============================
  Column Widths
==============================*/
.col-fixed,
.col-auto {
  flex: none;
}
.col-auto {
  width: auto;
  max-width: 100%;
}
@for $i from 1 through 12 {
  .col-#{$i} {
    width: ($i/12) * 100%;
    flex: none;
  }
}
