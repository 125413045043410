@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__your-sunpath-diagrams {
  .sunpath-diagram {
    display: block;
    margin: 0 auto 0 auto;
  }
}

/*==============================
  Web Specific Styles
==============================*/
.quote-page--web {
  .sunpath-diagram {
    width: 100%;
    height: auto;
    max-width: 740px;
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
  .sunpath-diagram {
    width: 740px;
    height: 313px;
  }
}
