@import '../../../scss/helpers/variables';

.quote__cancellation_form {
  max-width: 70%;
  padding-left: 24px;

  .bold_link {
    font-weight: 900;
  }
}
