@import '../../scss/helpers/variables';
@import '../../scss/helpers/mixins';

/*==============================
  Shared Quote Page Styles
==============================*/
.quote-page {
  h5 {
    display: inline-block;
    width: auto;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 12px;
    border-bottom: 2px solid $eon-turquoise;
    margin-bottom: 12px;
  }
  ol,
  ul {
    padding-left: 1.2em;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    td,
    th {
      text-align: left;
      padding: 12px 6px;
      position: relative;
    }

    th {
      font-weight: bold;
    }

    tr:first-of-type {
      td:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 6px;
        right: 6px;
        height: 2px;
        background: $eon-turquoise;
      }
    }

    td:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 6px;
      right: 6px;
      height: 1px;
      background: $copy-light;
    }
  }
}

.quote-section {
}

.quote-section__heading {
  margin-bottom: 0;
  background-color: $eon-bordeaux;
  // background-image set inline (it used env var)
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 659px auto;
  border-left: 15px solid $eon-lime;
  border-right: 80px solid $eon-turquoise;
  padding: 25px 30px 25px 15px;
  color: #ffffff;
  font-size: 35px;
}

.quote-section__content {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.quote-section__content--left,
.quote-section__content--right {
  display: table-cell;
  width: auto;
  padding: 30px;
  vertical-align: top;
}
.quote-section__content--right {
  width: 350px;
}

.quote__entry-block {
  max-width: 480px;
  margin: 24px 0px;
  padding: 12px;
  background-color: $background;
  border-left: 5px solid $eon-turquoise;
}

/*==============================
  Web Specific Styles
==============================*/
.quote-page--web {
  padding: 55px 0 0 0;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

/*-- Responsive --*/
@media screen and (max-width: 1023px) {
  .quote-page--web {
    .quote-section__heading {
      border-left: 8px solid $eon-lime;
      border-right: 15px solid $eon-turquoise;
      padding: 15px 22px;
      font-size: 24px;
      line-height: 26px;
    }
    .quote-section__content--left,
    .quote-section__content--right {
      display: block;
      width: 100%;
    }
  }
}
@media screen and (max-width: 800px) {
  .quote-page--web {
    padding: 0;
    width: 100%;
    .quote-section__content--left,
    .quote-section__content--right {
      padding: 30px 20px;
    }
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
  padding: 0;
  .quote-section__content--right {
    width: 300px;
  }
}

// PDF Page Wrappers
.pdf-page {
  position: relative;
  padding-bottom: 5rem; // clear the footer
}
.pdf-page:not(:last-child) {
  page-break-after: always;
}
// .pdf-page--decorated {
//   border-right: 56px $eon-turquoise;
// }
// .pdf-page--decorated .quote-section__content {
//   border-right: 24px $eon-lime;
// }

.pdf-page__footer {
  display: inline-block;
  font-size: 1rem;
  font-weight: 900;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
