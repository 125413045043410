@import '../../scss/helpers/variables';

.eon-logo {
  display: block;
  width: 141px;
  height: 69px;
  color: $eon-red;
}
.eon-logo.inverted {
  color: #ffffff;
}
