@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

.solar_contact {
  background: $eon-red;
  padding: 30px;
  border-right: 15px solid $eon-lime;
  color: #ffffff;
}
.pdf {
  .solar_contact {
    background: none;
    border-right: 0;
  }
}

.profile_container {
  min-width: 140px;
  display: inline-flex;

  .profile_container_column_a {
    width: 70px;
    height: 70px;
    margin-bottom: 24px;
  }

  // We always force an image via img.onError
  .profile_container_column_b {
    width: auto;
    vertical-align: middle;
    margin-bottom: 24px;
    p {
      margin-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }
  }
  .profile_picture_image {
    width: 68px;
    height: 68px;
    border: 1px solid #bfbfbf;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .solar_contact {
    padding: 30px 20px;
  }
}
