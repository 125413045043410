@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__survey-details__roofs-table-wrapper {
  margin-bottom: 30px;
}
.quote__survey-details__roofs-table {
  th {
    text-align: left;
  }
  td {
    vertical-align: middle;
  }
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 1023px) {
  .quote-page--web {
    .quote__survey-details__roofs-table-wrapper {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .quote__survey-details__roofs-table {
      width: auto !important;
      th,
      td {
        min-width: 120px;
      }
      .key {
        width: 220px;
        min-width: 220px;
      }
    }
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
