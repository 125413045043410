@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

/*==============================
  Shared Styles
==============================*/
.quote__intro {
  border-left: 15px solid $eon-lime;
  border-right: 80px solid $eon-turquoise;
  margin-bottom: 0;
  background: $eon-bordeaux;

  h1 {
    text-align: right;
    padding: 30px;
    background: #ffffff;
    max-width: 659px;
    min-height: 375px;
    font-size: 65px;
    line-height: 1;
    // background-image set inline (uses env var)
    background-repeat: no-repeat;
    background-size: top left;
  }
}
.quote__benefits-and-contact {
  direction: rtl;
}
.quote__benefits-and-contact .quote-section__content--left,
.quote__benefits-and-contact .quote-section__content--right {
  direction: ltr;
}

.quote__benefits {
  margin: 0 -10px;
}
.quote__benefits__benefit {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 680px) {
  .quote-page--web {
    .quote__intro {
      border: 0;
      h1 {
        padding: 30px 20px;
        font-size: 45px;
        min-height: 325px;
        max-width: none;
        background-size: cover;
        background-position: center left;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .quote-page--web {
    .quote__benefits__benefit {
      width: 50%;
    }
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
