@import '../../../scss/helpers/variables';
@import '../../../scss/helpers/mixins';

/*==============================
  Shared Styles
==============================*/
.quote__your-eon-solution {
}

// Includes list
.quote__your-eon-solution__includes {
  display: table;
  width: 100%;
  margin-bottom: 24px;
}
.quote__your-eon-solution__includes__col {
  display: table-cell;
  width: 50%;
}

.quote__your-eon-solution__includes__col:nth-child(1) {
  padding-right: 15px;
}
.quote__your-eon-solution__includes__col:nth-child(2) {
  padding-left: 15px;
}

// Testimonials
.quote__your-eon-solution__testimonials {
  background: $background-light;
  blockquote {
    font-size: 16px;
    line-height: 22px;
    padding: 20px;
    background: $eon-lime;
    border-radius: 36px;
    position: relative;

    p {
      margin-bottom: 12px;
      display: block;
    }
    cite {
      font-weight: bold;
      display: block;
    }
  }
  blockquote:before {
    display: none;
  }
  blockquote:after {
    content: '';
    position: absolute;
    @include background-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 61"><path d="M8.32 0h51.36A8.15 8.15 0 0 1 68 8v45a8.15 8.15 0 0 1-8.32 8 8.52 8.52 0 0 1-5.61-2.09L2.7 13.86a7.75 7.75 0 0 1-.52-11.27A8.49 8.49 0 0 1 8.32 0z" fill="#e3e000"/></svg>'
    );
    width: 56px;
    height: 50px;
    background-size: 100%;
  }
  blockquote:nth-child(odd):after {
    bottom: -40px;
    right: 45px;
  }
  blockquote:nth-child(even):after {
    top: -40px;
    left: 45px;
    transform: rotate(180deg);
  }
  blockquote + blockquote {
    margin-top: 90px;
  }
}

.quote__your-eon-solution__pricing {
  // have to put a tiny row in as table-layout: fixed takes the first row for widths, if we want to
  // span all three rows, then this is how to do it.
  // feel free to replace with a better method if you know one.
  tr.fixed_tiny_row {
    max-height: 1px;
    padding: 0;
    margin: 0;
    line-height: 0px;
    height: 1px;
    td {
      max-height: 1px;
      padding: 0;
      margin: 0;
      line-height: 0px;
      height: 1px;
    }
  }
  td {
    vertical-align: middle;
  }
  .fixed_title {
    min-width: 200px;
  }
  .fixed_unit {
    width: 30px;
    max-width: 30px;
    text-align: center;
  }
  .fixed_total {
    text-align: right;
    width: 200px;
    max-width: 200px;
  }
  .unit {
    width: 30px;
    text-align: center;
  }
  .total {
    text-align: right;
    width: 200px;
  }
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 680px) {
  .quote-page--web {
    .quote__your-eon-solution__includes__col {
      display: block;
      width: 100%;
      padding: 0;
    }
  }
}
