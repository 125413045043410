@import '../../../../scss/helpers/variables';

.benefit {
  .benefit_icon {
    display: flex;
    flex-direction: center;
    align-items: center;
    height: 64px;
    width: 64px;
    margin: 0 auto 20px auto;
  }
  h4 {
    border-top: 2px solid $eon-turquoise;
    border-bottom: 2px solid $eon-turquoise;
    padding: 20px 0;
    min-height: 60px;
    &.nowrap {
      white-space: nowrap;
    }
  }

  .benefit_tagline {
    min-height: 40px;
    font-size: 0.95em;
  }
}

.pdf {
  .benefit_tagline {
    //border-bottom: 2px solid black;
    //margin-bottom: 20px;
  }
}
