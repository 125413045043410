@import '../../../scss/helpers/variables';

/*==============================
  Shared Styles
==============================*/
.quote__agree-and-sign__order-includes {
  margin: 0 -15px;
}
.quote__agree-and-sign__order-includes__col {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 24px;
}

.quote__agree-and-sign__pricing-table,
.quote__agree-and-sign__order-table {
  max-width: 440px;
  // have to put a tiny row in as table-layout: fixed takes the first row for widths, if we want to
  // span all three rows, then this is how to do it.
  // feel free to replace with a better method if you know one.
  tr.fixed_tiny_row {
    max-height: 1px;
    padding: 0;
    margin: 0;
    line-height: 0px;
    height: 1px;
    td {
      max-height: 1px;
      padding: 0;
      margin: 0;
      line-height: 0px;
      height: 1px;
    }
  }
  td {
    vertical-align: middle;
  }
  td {
    &:before,
    &:after {
      left: 0 !important;
      right: 0 !important;
    }
  }
  .unit {
    width: 30px;
    text-align: center;
  }
  .total {
    width: 100px;
    text-align: right;
  }
}

.quote__agree-and-sign__agreement-box {
  background: $background;
  border-radius: 12px;
  padding: 20px 30px;
  margin-bottom: 30px;

  .hollywood-checkbox:disabled + .hollywood-checkbox-label {
    color: $copy;
    cursor: not-allowed;
  }
}

.quote__agree-and-sign__signature-box {
  max-width: 400px;
  height: 120px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-bottom: 24px;
}

.quote__agree-and-sign__cooling-off {
  margin: 0 -15px 24px -15px;
}
.quote__agree-and-sign__cooling-off__declaration,
.quote__agree-and-sign__cooling-off__address-details {
  float: left;
  padding: 0 15px;
}
.quote__agree-and-sign__cooling-off__declaration {
  width: 58%;
}
.quote__agree-and-sign__cooling-off__address-details {
  width: 42%;
}

.quote__agree-and-sign__agree-order {
  text-align: right;
}

.quote__agree-and-sign__expired-msg {
  padding: 0.5em 0.75em 0.625em 0.75em;
  font-weight: 900;
  background: #b00402;
  border-radius: 10px;
  color: #ffffff;
  a {
    text-decoration: underline;
  }
}

/*==============================
  Web Specific Styles
==============================*/
@media screen and (max-width: 800px) {
  .quote-page--web {
    .quote__agree-and-sign__cooling-off__declaration,
    .quote__agree-and-sign__cooling-off__address-details {
      float: none;
      width: 100%;
    }
    .quote__agree-and-sign__cooling-off__declaration {
      margin-bottom: 24px;
    }
  }
}
@media screen and (max-width: 680px) {
  .quote-page--web {
    .quote__agree-and-sign__order-includes__col {
      float: none;
      width: 100%;
    }
    .quote__agree-and-sign__agree-order {
      text-align: left;
    }
  }
}

/*==============================
  PDF Specific Styles
==============================*/
.quote-page--pdf {
}
